import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const Code = makeShortcode("Code");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` supports the Automated Certificate Management Environment (ACME) protocol.
You can get X.509 certificates from your own certificate authority (CA) using `}<a parentName="p" {...{
        "href": "https://letsencrypt.org/docs/client-options/"
      }}>{`popular ACME clients and libraries`}</a>{`, or via the `}<inlineCode parentName="p">{`step`}</inlineCode>{` command's built-in ACME client.`}</p>
    <p>{`ACME is a modern, standardized protocol for automatic validation and issuance of X.509 certificates from a CA to clients.`}</p>
    <h2>{`In this document`}</h2>
    <ul>
      <li parentName="ul">{`Learn about the ACME certificate flow and the most common ACME challenge types.`}</li>
      <li parentName="ul">{`Learn how to use an ACME challenge to issue X.509 certificates to endpoints automatically.`}</li>
      <li parentName="ul">{`Configure `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` to enable ACME, and get your first certificate via `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/certificate"
        }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{`.`}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know in <Link href="https://u.step.sm/discord" mdxType="Link">Discord</Link> or in <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open source -`}</strong>{` This tutorial assumes you have initialized and started up a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` server (see `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`).`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://smallstep.com/certificate-manager"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` follow the instructions provided in the Certificate Manager `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/acme"
        }}>{`ACME documentation`}</a>{`.`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#why-acme"
        }}>{`Why ACME?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#a-typical-acme-flow"
        }}>{`A Typical ACME Flow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#acme-challenges"
        }}>{`ACME Challenges`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configure-step-ca-for-acme"
        }}>{`Configure `}<inlineCode parentName="a">{`step-ca`}</inlineCode>{` for ACME`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#next-steps"
        }}>{`Next Steps`}</a></li>
    </ul>
    <h2>{`Why ACME?`}</h2>
    <p>{`With ACME, machines can get certificates from a CA without any human interaction involved.`}</p>
    <p>{`For example, you can:`}</p>
    <ul>
      <li parentName="ul">{`Use ACME in production to issue X.509 certificates to internal workloads, proxies, queues, databases, etc. so you can use mutual TLS for authentication and encryption.`}</li>
      <li parentName="ul">{`Simulate Let’s Encrypt’s certificate authority in development and
pre-production scenarios where connecting to `}<a parentName="li" {...{
          "href": "https://letsencrypt.org/docs/staging-environment/"
        }}>{`Let’s Encrypt’s staging
server`}</a>{` is problematic.`}</li>
    </ul>
    <h2>{`A Typical ACME Flow`}</h2>
    <p>{`Here's how an ACME client might request an X.509 certificate from a CA.`}</p>
    <ol>
      <li parentName="ol">{`The ACME client creates an account with an ACME CA server and submits a certificate order. You can think of an ACME account as a place to store open certificate requests for that particular client.`}</li>
      <li parentName="ol">{`The CA responds with a set of challenges. To complete the challenges, the client must prove it controls each subject name (domain name, IP address, or hardware device ID) that it is requesting in the certificate.`}</li>
      <li parentName="ol">{`The CA verifies the client's challenge reponses.`}</li>
      <li parentName="ol">{`Once the client successfully completes the ACME challenges, it submits a certificate signing request (CSR) to the CA.`}</li>
      <li parentName="ol">{`The CA verifies that the client has control of the private key associated with the certificate request.`}</li>
      <li parentName="ol">{`The CA issues a certificate to the client.`}</li>
    </ol>
    <p>{`The ACME API for certificate requests requires an HTTPS connection between the client and CA.`}</p>
    <h2>{`ACME Challenge Types`}</h2>
    <p>{`There is not a single standard way to prove control over an identifier, so the
core ACME specification makes this an extension point.
Today, most commonly, ACME clients respond to challenges via HTTP, DNS, or TLS protocols.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Identifier Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="th">{`http-01`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="th">{`dns-01`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="th">{`tls-alpn-01`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="th">{`device-attest-01`}</inlineCode></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IP address`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hostname`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device ID`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`A Device ID may be a device's serial number, or another identifier assigned at manufacture.`}</p>
    <h3>{`The HTTP Challenge (http-01)`}</h3>
    <p>{`The ACME CA challenges the client to host a random number at a random URL under `}<inlineCode parentName="p">{`/.well-known/acme-challenge`}</inlineCode>{` on port 80. The CA verifies client control by issuing an HTTP GET request to that URL.`}</p>
    <p><strong parentName="p">{`When To Use It`}</strong></p>
    <p>{`This is a good general-purpose challenge type.
By hosting the challenge response via HTTP on port 80, the client proves its control over a protected port on the domain being requested.
The `}<inlineCode parentName="p">{`http-01`}</inlineCode>{` challenge type is the easiest to set up, because any web server will let you host the challenge response as a static file.`}</p>
    <h3>{`The DNS Challenge (dns-01)`}</h3>
    <p>{`The ACME CA challenges the client to provision a random DNS `}<inlineCode parentName="p">{`TXT`}</inlineCode>{` record for the domain in question. It verifies the challenge by querying DNS for that `}<inlineCode parentName="p">{`TXT`}</inlineCode>{` record.`}</p>
    <p><strong parentName="p">{`When To Use It`}</strong></p>
    <p>{`The `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{` challenge type is good if your ACME server cannot reach the requested domain directly.
The server only needs to be able to perform a DNS lookup to confirm the challenge.
However, because the ACME client needs to modify DNS records, configuring a `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{` client is usually more involved.`}</p>
    <h3>{`The TLS ALPN challenge (tls-alpn-01)`}</h3>
    <p>{`The ACME CA uses TLS to validate a challenge, leveraging application layer protocol negotiation (ALPN) in the TLS handshake.
The client presents a self-signed TLS certificate containing the challenge response as a special X.509 certificate extension.`}</p>
    <p><strong parentName="p">{`When To Use It`}</strong></p>
    <p>{`This challenge type is useful when a security policy requires the CA to reach the client via a TLS connection.
This is a popular challenge type in cases where an ingress controller fronts clients attempting to receive a certificate.`}</p>
    <h3>{`The Device Attestation Challenge (device-attest-01)`}</h3>
    <p>{`You can use `}<Code mdxType="Code">{`device-attest-01`}</Code>{` for Apple `}<a href="https://support.apple.com/guide/deployment/managed-device-attestation-dep28afbde6a/web">{`Managed Device Attestation (MDA)`}</a>{` and other secure zero-touch provisioning (SZTP) applications as part of your device management (MDM) strategy. `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` supports attestation challenges using Apple devices and YubiKeys.`}</p>
    <p>{`Certificates identify specific hardware devices, via permanent device IDs.
These are typically client certificates that can be used for device authentication.`}</p>
    <h2>{`Configure `}<inlineCode parentName="h2">{`step-ca`}</inlineCode>{` for ACME`}</h2>
    <p>{`Let's get an X.509 certificate via the ACME `}<inlineCode parentName="p">{`http-01`}</inlineCode>{` challenge, using `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p>{`First, `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#acme"
      }}>{`add an ACME provisioner`}</a>{` to your `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` configuration to enable ACME support:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add acme --type ACME
`}</code></pre>
    <p>{`You may need to restart `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to pick up the configuration changes.`}</p>
    <p>{`Finally, use `}<inlineCode parentName="p">{`step`}</inlineCode>{` as an ACME client to request a certificate.
The client will start a web server on port 80 to respond to the ACME challenge.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca certificate example.com --provisioner acme
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`✔ Provisioner: acme (ACME)
Using Standalone Mode HTTP challenge to validate example.com . done!
Waiting for Order to be 'ready' for finalization .. done!
Finalizing Order .. done!
✔ Certificate: example.crt
✔ Private Key: example.key
`}</code></pre>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul">{`Start tailoring `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` to your infrastructure. See `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners"
        }}>{`Provisioners`}</a>{`, `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/certificate-authority-server-production"
        }}>{`Production Considerations`}</a>{`, and our `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration"
        }}>{`Configuration Guide`}</a>{`.`}</li>
      <li parentName="ul">{`Tutorial: Learn `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/tutorials/acme-protocol-acme-clients"
        }}>{`how to configure the most popular ACME clients`}</a>{` to connect to a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` server.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      